import React from 'react';
import PropTypes from 'prop-types';

export const MicLayout = ({ children, year }) => {
  return (<div id={`mic__layout__${year ? year : 2022}`}>{children}</div>)
};

MicLayout.propTypes = {
  children: PropTypes.any
}
